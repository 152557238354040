<template>
  <v-container
    id="user-login"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <h1> Logging out </h1>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    mounted () {
      localStorage.removeItem('jwt')
      this.$store.commit('SET_AUTH', false)
      location.reload()
      this.$router.push({ path: '/' })
    },
  }
</script>

<style></style>
